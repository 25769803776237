import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { colors } from "style_dependencies/variables";
import { Box, Heading } from "theme-ui";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import axios from "axios";

const baseUrl = "https://api.fapshi.com/";
const route = "rates";
const GATEWAY_URL = baseUrl + route;

const rows = [
  {
    id: 2,
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.7em", height: "0.7em" }}
        fontSize="small"
      />
    ),
    text: "Payouts available 24/7",
    charge: "",
  },
  {
    id: 1,
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.7em", height: "0.7em" }}
        fontSize="small"
      />
    ),
    text: "Payouts (Money transfer/withdrawal from your Fapshi account)",
    charge: "Free",
  },
  {
    id: 3,
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.7em", height: "0.7em" }}
        fontSize="small"
      />
    ),
    text: "Fapshi Transfers",
    charge: "0.5%",
  },
];

export default function AcccessibleTable() {
  const [rates, setRates] = useState([]);
  const [blur, setBlur] = useState(true);

  useEffect(() => {
    const getRates = async () => {
      const response = await axios.get(GATEWAY_URL).catch((error) => {
        if (error && error.response) {
          console.log(error.response);
        }
      });
      if (response && response.data) {
        setRates(response.data);
        setBlur(false);
      }
    };
    getRates();
  }, []);

  const { transfer } = rates.rates || {};

  const { rate: transferRate } = transfer || {};

  return (
    <Box sx={blur && styles.blur}>
      <TableContainer component={Paper} sx={styles.table}>
        <Table aria-label="transfer pricing table">
          <TableHead>
            <Heading sx={styles.table.heading}>Others</Heading>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" sx={styles.table.cell}>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemAvatar sx={{ minWidth: "40px" }}>
                      <Avatar sx={styles.table.listIcon}>{row.icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography sx={styles.table.text}>
                          {row.text}
                        </Typography>
                      }
                    />
                  </ListItem>
                </TableCell>
                <TableCell align="right" sx={styles.table.cell}>
                  {row.id === 3 ? transferRate + "%" : row.charge}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const styles = {
  blur: {
    filter: "blur(8px)",
    "-webkit-filter": "blur(8px)",
  },

  table: {
    py: ["20px", null, "30px", null, "40px"],
    px: ["5%", "10%", "12.5%"],
    my: ["30px", null, "45px", null, "60px"],
    boxShadow: "none",

    heading: {
      fontWeight: "bold",
      fontSize: "2.5rem",
      color: colors.btnColor,
      mb: 2,
    },

    text: {
      fontFamily: "Hind",
      fontSize: "18px",
      p: 0,
      m: 0,
    },

    cell: {
      fontFamily: "Hind",
      fontSize: "18px",
      fontWeight: "bold",
      px: 0,
      py: "10px",
      borderBottom: "1px solid rgba(224, 224, 224, 0.2)",
    },
    listIcon: {
      width: "20px",
      height: "20px",
      bgcolor: colors.secondary,
    },
  },
};
