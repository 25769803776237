import { Box, Card, Heading, Button, Text } from "theme-ui";
import React, { useState, useEffect } from "react";
import List from "./list";
import Grid from "@mui/material/Grid";
import { IoIosCheckmarkCircle } from "react-icons/io";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import axios from "axios";
import { colors, strings } from "style_dependencies/variables";

const baseUrl = "https://api.fapshi.com/";
const route = "rates";
const GATEWAY_URL = baseUrl + route;

const paymentData = [
  {
    points: [
      {
        icon: <IoIosCheckmarkCircle />,
        text: "No hidden fees or charges",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "No startup or integration fees",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Free payouts available 24/7",
        isAvailable: true,
      },
    ],
  },
];

const tableData = [
  {
    points: [
      {
        icon: <IoIosCheckmarkCircle />,
        text: "No hidden fees or charges",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Free payouts available 24/7",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Seamless and quick procedure",
        isAvailable: true,
      },
    ],
  },
];

export default function PriceCalculator() {
  const [showDiv, setShowDiv] = useState(3);
  const [input, setInput] = useState("10000");
  const [cardInput, setCardInput] = useState("500000");
  const [transInput, setTransInput] = useState("100000");
  const [rates, setRates] = useState([]);
  const [blur, setBlur] = useState(true);

  useEffect(() => {
    const getRates = async () => {
      const response = await axios.get(GATEWAY_URL).catch((error) => {
        if (error && error.response) {
          console.log(error.response);
        }
      });
      if (response && response.data) {
        setRates(response.data);
        setBlur(false);
      }
    };
    getRates();
  }, []);

  const { transfer, vendor, cardRate, cardFee, dollarRate } = rates.rates || {};

  const { rate: vendorRate } = vendor || {};

  const { rate: transferRate } = transfer || {};

  const thousandSeparator = (value) => {
    return new Intl.NumberFormat().format(parseFloat(value));
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const inputTests = (e, stateFxn) => {
    const re = /^[0-9\b]+$/;
    const numChars = /^.{,8}$/;

    if (
      e.target.value === "" ||
      re.test(e.target.value) ||
      numChars.test(e.target.value)
    ) {
      stateFxn(e.target.value);
    }
  };

  const inputChange = (e) => {
    inputTests(e, setInput);
  };

  const cardInputChange = (e) => {
    inputTests(e, setCardInput);
  };

  const transInputChange = (e) => {
    inputTests(e, setTransInput);
  };

  const payment = (pdtPrice, rate) => {
    let revenue = 0;
    let charges = 0;

    if (pdtPrice <= 0) {
      console.log("Your price is invalid!");
    } else {
      charges = (pdtPrice * parseFloat(rate)) / 100;
    }

    revenue = parseInt(pdtPrice) - charges;

    return [Math.floor(revenue), Math.ceil(charges)];
  };

  const intlPayment = (amount) => {
    let revenue = 0;
    let charges = 0;

    if (amount <= 0) {
      console.log("Your amount is invalid!");
    } else {
      revenue =
        amount * (1 - parseFloat(cardRate) / 100) -
        (cardFee / 100) * dollarRate;
    }

    charges = parseInt(amount) - revenue;

    return [Math.floor(revenue), Math.ceil(charges)];
  };

  const transferFxn = (amount) => {
    let transferAmt = 0;

    if (amount <= 0) {
      console.log("You can't transfer that amount");
    } else {
      transferAmt =
        parseInt(amount) + parseInt(amount) * (parseFloat(transferRate) / 100);
    }

    let charges = Math.ceil(transferAmt) - amount;

    return [Math.ceil(transferAmt), charges];
  };

  const errorCheck = (amount, type) => {
    if (type === "payout") return amount < parseInt(100);
    else return amount < parseInt(500);
  };

  return (
    <Box sx={blur && styles.blur}>
      <Card sx={styles.pricingBox}>
        <Grid
          container
          sx={{
            alignItems: "center",
            mb: 4,
          }}
        >
          <Grid item xs={12}>
            <Heading sx={styles.heading} style={{ "font-size": "16px" }}>
              Select an option
            </Heading>
          </Grid>
          <Grid item xs={12} sx={styles.btns}>
            <Button
              mr={2}
              sx={showDiv === 3 ? styles.btns.payment : styles.btns.topup}
              onClick={() => setShowDiv(3)}
            >
              Local Payments
            </Button>
            <Button
              mr={2}
              sx={showDiv === 4 ? styles.btns.payment : styles.btns.topup}
              onClick={() => setShowDiv(4)}
            >
              International Payments
            </Button>
            <Button
              sx={showDiv === 5 ? styles.btns.payment : styles.btns.topup}
              onClick={() => setShowDiv(5)}
            >
              Transfers
            </Button>
          </Grid>
        </Grid>
        {showDiv === 3 && (
          <Box id="localPaymentBox">
            <Box>
              <Heading sx={styles.heading}>IF YOUR CLIENT PAYS</Heading>
              <Input
                sx={styles.input}
                onKeyPress={handleKeyPress}
                onChange={inputChange}
                value={input}
                inputProps={{ maxLength: 8 }}
                endAdornment={
                  <InputAdornment position="end" sx={styles.input.adornment}>
                    {strings.currency}
                  </InputAdornment>
                }
              />
              {errorCheck(input) && (
                <Text sx={styles.error}>
                  We do not accept payments less than 500 {strings.currency}!
                </Text>
              )}
            </Box>

            <Grid
              container
              sx={{
                my: 4,
              }}
            >
              <Grid item xs={12} sm={6}>
                <Heading sx={styles.heading}>YOUR REVENUE</Heading>
                <Heading sx={styles.amount}>
                  {input === "" ? (
                    `0 ${strings.currency}                    `
                  ) : errorCheck(input) ? (
                    <Text sx={{ color: "tomato" }}>Not Allowed!</Text>
                  ) : (
                    thousandSeparator(payment(input, vendorRate)[0]) +
                    " " +
                    strings.currency
                  )}
                </Heading>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  textAlign: "right",
                  "@media (max-width: 600px)": {
                    mt: 2,
                    textAlign: "left",
                  },
                }}
              >
                <Heading sx={styles.heading}>
                  CHARGES: {vendorRate + "%"}
                </Heading>
                <Heading sx={styles.amount}>
                  {thousandSeparator(payment(input, vendorRate)[1])}{" "}
                  {strings.currency}
                </Heading>
              </Grid>
            </Grid>
            {paymentData.map((paymentData) => (
              <List
                items={paymentData.points}
                childStyle={styles.listItem}
                key={"paymentData" + paymentData.points.text}
              />
            ))}
          </Box>
        )}
        {showDiv === 4 && (
          <Box id="IntlPaymentBox">
            <Box>
              <Heading sx={styles.heading}>IF YOUR CLIENT PAYS</Heading>
              <Input
                sx={styles.input}
                onKeyPress={handleKeyPress}
                onChange={cardInputChange}
                value={cardInput}
                inputProps={{ maxLength: 8 }}
                endAdornment={
                  <InputAdornment position="end" sx={styles.input.adornment}>
                    {strings.currency}
                  </InputAdornment>
                }
              />
              {errorCheck(cardInput) && (
                <Text sx={styles.error}>
                  We do not accept payments less than 500 {strings.currency}!
                </Text>
              )}
            </Box>

            <Grid
              container
              sx={{
                my: 4,
              }}
            >
              <Grid item xs={12} sm={6}>
                <Heading sx={styles.heading}>YOUR REVENUE</Heading>
                <Heading sx={styles.amount}>
                  {cardInput === "" ? (
                    `0 ${strings.currency}`
                  ) : errorCheck(cardInput) ? (
                    <Text sx={{ color: "tomato" }}>Not Allowed!</Text>
                  ) : (
                    thousandSeparator(intlPayment(cardInput)[0]) +
                    " " +
                    strings.currency
                  )}
                </Heading>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  textAlign: "right",
                  "@media (max-width: 600px)": {
                    mt: 2,
                    textAlign: "left",
                  },
                }}
              >
                <Heading sx={styles.heading}>
                  CHARGES: {`${cardRate}% + ${cardFee}¢`}
                </Heading>
                <Heading sx={styles.amount}>
                  {thousandSeparator(intlPayment(cardInput)[1])}{" "}
                  {strings.currency}
                </Heading>
              </Grid>
            </Grid>
            {paymentData.map((paymentData) => (
              <List
                items={paymentData.points}
                childStyle={styles.listItem}
                key={"paymentData" + paymentData.points.text}
              />
            ))}
          </Box>
        )}
        {showDiv === 5 && (
          <Box id="transferBox">
            <Box>
              <Heading sx={styles.heading}>IF YOU WANT TO TRANSFER</Heading>
              <Input
                sx={styles.input}
                onKeyPress={handleKeyPress}
                onChange={transInputChange}
                value={transInput}
                inputProps={{ maxLength: 8 }}
                endAdornment={
                  <InputAdornment position="end" sx={styles.input.adornment}>
                    {strings.currency}
                  </InputAdornment>
                }
              />
              {errorCheck(transInput) && (
                <Text sx={styles.error}>
                  We do not accept transfers less than 500 {strings.currency}!
                </Text>
              )}
            </Box>

            <Grid
              container
              sx={{
                my: 4,
              }}
            >
              <Grid item xs={12} sm={6}>
                <Heading sx={styles.heading}>
                  WE WILL DEBIT THIS AMOUNT FROM YOUR ACCOUNT
                </Heading>
                <Heading sx={styles.amount}>
                  {transInput === "" ? (
                    `0 {strings.currency}`
                  ) : errorCheck(transInput) ? (
                    <Text sx={{ color: "tomato" }}>Not Allowed!</Text>
                  ) : (
                    thousandSeparator(transferFxn(transInput)[0]) +
                    " " +
                    strings.currency
                  )}
                </Heading>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  textAlign: "right",
                  "@media (max-width: 600px)": {
                    mt: 2,
                    textAlign: "left",
                  },
                }}
              >
                <Heading sx={styles.heading}>FEE: {transferRate + "%"}</Heading>
                <Heading sx={styles.amount}>
                  {thousandSeparator(transferFxn(transInput)[1])}{" "}
                  {strings.currency}
                </Heading>
              </Grid>
            </Grid>
            {tableData.map((tableData) => (
              <List
                items={tableData.points}
                childStyle={styles.listItem}
                key={"transferData" + tableData.points.text}
              />
            ))}
          </Box>
        )}
      </Card>
    </Box>
  );
}

const styles = {
  blur: {
    filter: "blur(8px)",
    "-webkit-filter": "blur(8px)",
  },
  pricingBox: {
    p: [
      "30px 20px 20px 20px",
      null,
      "30px 25px 30px 25px",
      null,
      "40px 40px 40px 40px",
    ],
    color: "white",
    background: colors.btnColor,
    borderRadius: 10,
    ml: [0, null, null, 5],
    mt: ["16px", null, null, 0],
  },
  btns: {
    display: "flex",
    alignItems: "center",
    mt: 2,
    justifyContent: "start",

    payment: { backgroundColor: colors.secondary },
    topup: {
      backgroundColor: "transparent",
      border: "1px solid white",
      borderRadius: "20px",
    },
  },
  input: {
    fontSize: "26px",
    fontFamily: "'Hind', sans-serif",
    mt: 2,
    border: "none",
    borderRadius: "5px",
    backgroundColor: colors.card1Btn,
    px: 3,
    py: 2,
    color: "white",
    width: "100%",

    "&&&:before": {
      borderBottom: "none !important",
    },
    "&&:after": {
      borderBottom: "none !important",
    },

    adornment: {
      ".MuiTypography-body1": {
        color: "white",
        fontSize: "24px",
        fontFamily: "Hind",
      },
    },

    "&:active, &:hover, &:focus": {
      boxShadow: colors.secondary + " 0 0 0 2px",
    },
  },
  error: {
    fontSize: "14px",
    color: "tomato",
    mt: "0.5rem",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: colors.secondary,
  },
  amount: {
    fontWeight: "bold",
    fontSize: ["20px", "30px", null, null, "40px"],
    color: "white",
    letterSpacing: "0.5px",
  },
  listItem: {
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "left",
    fontSize: "18px",
    lineHeight: [1.7, null, 1.65],
    marginBottom: [3, "22px"],
    alignItems: "flex-start",
    color: "white",
  },
};
