import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import SEO from "components/seo";
import Layout from "components/layout";
import Faq from "sections/faq";
import PricingBanner from "sections/pricing-banner";
import PricingCalculator from "sections/price-calculator";
import TransferCard from "components/transfer-card";
import ActionCards from "sections/info-cards";

// Load other package css file
import "react-multi-carousel/lib/styles.css";
import "react-modal-video/css/modal-video.min.css";
import "rc-drawer/assets/index.css";

const seoData = {
  title: "Pricing & Fees | Fapshi",
  description:
    "See how much we charge for invoice payments, payment links, merchant transactions or product sales. Use the pricing calculator to calculate your revenue per transaction.",
  keywords:
    "pricing fapshi, fapshi pricing, billing on fapshi, fapshi charges, fapshi fees, product charges on fapshi, calculate revenue on fapshi, fapshi price calculator, fapshi pricing calculator, pricing calculator",
  pathname: "pricing",
};

export default function PricingPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO data={seoData} />
        <PricingBanner />
        <TransferCard />
        <PricingCalculator />
        <ActionCards />
        <Faq />
      </Layout>
    </StickyProvider>
  );
}
