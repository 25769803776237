import { Box, Card, Text, Heading, Divider, Flex } from "theme-ui";
import React, { useState, useEffect } from "react";
import List from "./list";
import EastIcon from "@mui/icons-material/East";
import { colors } from "style_dependencies/variables";
import axios from "axios";
import { useEndpoint } from "../utils/useEndpoint";

const baseUrl = "https://api.fapshi.com/";
const route = "rates";
const GATEWAY_URL = baseUrl + route;

export default function PriceCard({
  data: { type, name, description, buttonText, btnUrl, points },
}) {
  const [rates, setRates] = useState([]);
  const [blur, setBlur] = useState(true);
  const { isLoading, getLink } = useEndpoint();

  useEffect(() => {
    const getRates = async () => {
      const response = await axios.get(GATEWAY_URL).catch((error) => {
        if (error && error.response) {
          console.log(error.response);
        }
      });
      if (response && response.data) {
        setRates(response.data);
        setBlur(false);
      }
    };
    getRates();
  }, []);

  const { vendor, merchantRate, cardRate, cardFee } = rates.rates || {};
  const { rate } = vendor || {};

  function open(url) {
    const win = window.open(url, "_blank");
    if (win !== null) {
      win.focus();
    }
  }

  const getDemoLink = async () => {
    const obtained = await getLink();
    if (obtained) {
      open(obtained);
    }
  };

  return (
    <Box sx={blur && styles.blur}>
      <Card className={type ? "white" : null} sx={styles.pricingBox}>
        <Box sx={styles.pricingHeader}>
          <Heading className="package__name" sx={styles.heading}>
            {name}
          </Heading>
        </Box>
        <Divider
          light="true"
          sx={{
            opacity: "0.5",
          }}
        />
        <Text
          as="p"
          sx={{
            color: type ? "text" : colors.cardText,
            my: ["20px", null, "30px", null, "30px"],
            p: [
              "0px 10px 0px 10px",
              null,
              "0px 25px 0px 25px",
              null,
              "0px 40px 0px 40px",
            ],
            fontSize: [1, 2],
            lineHeight: 1.3,
          }}
        >
          {description}
        </Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: type ? "space-between" : "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 1rem",
            }}
          >
            <Box sx={styles.price}>
              <Heading as="h2" sx={{ color: type ? colors.btnColor : "white" }}>
                {type ? merchantRate : rate}%
              </Heading>
              <Text sx={type ? styles.price.merchant : styles.price.vendor}>
                for local transactions
              </Text>
            </Box>
            <Box sx={styles.price}>
              <Heading as="h2" sx={{ color: type ? colors.btnColor : "white" }}>
                {`${cardRate}% + ${cardFee}¢`}
              </Heading>
              <Text sx={type ? styles.price.merchant : styles.price.vendor}>
                for international transactions
              </Text>
            </Box>
          </Box>
        </Box>
        <List items={points} childStyle={styles.listItem} />
        <Box sx={styles.btn} style={!type ? styles.card1 : styles.card2}>
          {btnUrl ? (
            <a
              href={btnUrl}
              style={{ textDecoration: "none" }}
              aria-label={buttonText}
              target="_blank"
              rel="noreferrer"
            >
              <Flex
                style={{ justifyContent: "center", alignItems: "center" }}
                sx={type ? styles.btn.darkBtn : styles.btn.whiteBtn}
              >
                {buttonText} <EastIcon sx={{ ml: 1, mt: "-3px" }} />
              </Flex>
            </a>
          ) : (
            <Box
              onClick={(e) => {
                e.preventDefault();
                getDemoLink();
              }}
            >
              <Flex
                style={{ justifyContent: "center", alignItems: "center" }}
                sx={type ? styles.btn.darkBtn : styles.btn.whiteBtn}
              >
                {isLoading ? (
                  "Please wait..."
                ) : (
                  <>
                    {buttonText} <EastIcon sx={{ ml: 1, mt: "-3px" }} />
                  </>
                )}
              </Flex>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}

const styles = {
  blur: {
    filter: "blur(8px)",
    "-webkit-filter": "blur(8px)",
  },
  pricingBox: {
    flex: ["0 100%", null, null, "0 50%", "0 45%", "0 40%", "0 38.5%"],
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px !important",
    position: "relative",
    background: colors.btnColor,
    borderRadius: 10,
    ml: [0, null, null, 5],
    mt: ["16px", null, null, 0],
    p: 0,
    pb: "40px",
    "&.white": {
      backgroundColor: "white",
      "@media screen and (max-width: 767px)": {
        mt: "75px",
      },
      ".package__name": {
        color: "heading_secondary",
      },
      ".open": {
        color: "text",
      },
    },
  },
  pricingHeader: {
    mb: ["20px", null, "30px", null, "30px"],
    p: [
      "20px 10px 0px 10px",
      null,
      "30px 25px 0px 25px",
      null,
      "40px 40px 0px 40px",
    ],
    textTransform: "uppercase",
  },
  heading: {
    fontWeight: "bold",
    fontSize: [4, null, null, null, 5],
    lineHeight: "23px",
    color: "white",
    marginBottom: [1, null, "12px"],
  },
  price: {
    lineHeight: 1,
    mb: ["20px", null, "30px", null, "30px"],
    p: ["0px 30px 0px 30px"],

    h2: {
      fontWeight: "bold",
      fontSize: ["24px", null, null, null, "30px"],
    },

    merchant: {
      lineHeight: "1.5",
    },

    vendor: {
      lineHeight: "1.5",
      color: "white",
    },
  },
  listItem: {
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "left",
    fontSize: [1, null, null, null, 2],
    lineHeight: [1.7, null, 1.65],
    marginBottom: [3, "22px"],
    alignItems: "flex-start",
    color: "white",
    p: [
      "0px 15px 0px 15px",
      null,
      "0px 25px 0px 25px",
      null,
      "0px 40px 0px 40px",
    ],
  },

  card1: {
    backgroundColor: colors.card1Btn,
  },
  card2: {
    backgroundColor: colors.card2Btn,
  },

  btn: {
    textTransform: "uppercase",
    py: "16px",
    fontSize: [4, null, null, null, 5],
    cursor: "pointer",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "absolute",
    bottom: "-10%",
    width: "100%",
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px !important",

    darkBtn: {
      textDecoration: "none",
      color: colors.btnColor,
    },

    whiteBtn: {
      textDecoration: "none",
      color: "white",
    },
  },
};
