import React from "react";
import { Box, Container, Image, Heading, Flex } from "theme-ui";
import { keyframes } from "@emotion/core";
import bannerIcon1 from "assets/banner-icon-1.svg";
import bannerIcon2 from "assets/banner-icon-2.svg";
import PriceCard from "components/price-card";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { colors } from "../style_dependencies/variables";

const packages = [
  {
    name: "Payment Links, Products, Invoices",
    description:
      "We charge for successful transactions only; Fapshi prides in your success.",
    priceText: "for every successful product purchase or invoice payment",
    buttonText: "Signup Now",
    btnUrl: "https://dashboard.fapshi.com/register",
    points: [
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Customizable, easy-to-use payment links",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Short, user-friendly store/product links",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Withdraw your funds at anytime",
        isAvailable: true,
      },
    ],
  },
  {
    type: "white",
    name: "Merchant",
    description:
      "Integrate a complete payments gateway with simple, transparent pricing.",
    buttonText: "Try a demo",
    points: [
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Everything you need for payments",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "Create multiple services",
        isAvailable: true,
      },
      {
        icon: <IoIosCheckmarkCircle />,
        text: "SDKs to help you integrate quickly",
        isAvailable: true,
      },
    ],
  },
];

const PricingBanner = () => {
  return (
    <Box as="section" id="pricingBanner" sx={styles.banner}>
      <Container sx={styles.container}>
        <Image
          sx={styles.bannerIcon1}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon1}
        />
        <Image
          sx={styles.bannerIcon2}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon2}
        />

        <Heading as="h1" sx={styles.container.heading}>
          Our pricing rates are fair, cheap & transparent
        </Heading>
        <Flex
          sx={{
            justifyContent: "center",
            flexWrap: ["wrap", null, null, "nowrap"],
          }}
        >
          {packages.map((packageData) => (
            <PriceCard data={packageData} key={packageData.name} />
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default PricingBanner;

const bannerAnim = keyframes`
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(50px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
`;

const styles = {
  banner: {
    overflow: "hidden",
    backgroundColor: colors.mainColor,
    textAlign: "center",
    py: ["140px", null, null, null, "150px"],
  },
  container: {
    position: "relative",
    ".bannerIcon": {
      position: "absolute",
      display: ["none", null, null, null, "block"],
    },
    heading: {
      fontSize: ["2.5rem", null, "3rem"],
      lineHeight: 1.25,
      color: "#02073E",
      fontWeight: 700,
      width: "100%",
      maxWidth: ["100%", null, null, "600px", "740px", "851px"],
      mx: "auto",
      mt: ["50px", null, null, "80px"],
      mb: ["20px", null, null, "65px"],
    },
  },
  bannerIcon1: {
    top: "10%",
    left: "5%",
    animation: `${bannerAnim} 8s linear infinite`,
  },
  bannerIcon2: {
    top: "10%",
    right: "5%",
    animation: `${bannerAnim} 8s linear infinite`,
  },
};
