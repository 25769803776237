import React from "react";
import { Box, Container, Heading, Text } from "theme-ui";
import PriceCalculator from "components/pricing-calculator";
import { colors } from "../style_dependencies/variables";
import Grid from "@mui/material/Grid";

export default function PricingCalculator() {
  return (
    <Box as="section" id="pricingCalculator" sx={styles.banner}>
      <Container sx={styles.container}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={5} sx={styles.container.text}>
            <Heading sx={styles.container.heading}>Do the Math</Heading>
            <Text sx={styles.container.text}>
              See how much you get for every successful transaction, payments
              via your payment links, invoice payments, or product sales. Select
              the appropriate menu item and enter an amount into the calculator.
            </Text>
          </Grid>
          <Grid item xs={12} sm={7}>
            <PriceCalculator />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const styles = {
  banner: {
    backgroundColor: colors.mainColor,
    py: ["60px", null, null, null, "100px"],
  },
  container: {
    px: "10px !important",
    text: {
      pr: ["0", "20px", null, "40px", "50px"],
      fontSize: "18px",
    },
    heading: {
      fontSize: "2.5rem",
      lineHeight: 1.25,
      color: "#02073E",
      fontWeight: 700,
      width: "100%",
      mx: "auto",
      mb: ["10px", null, null, "15px"],
    },
  },
};
